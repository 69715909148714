//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatGtm, useGTM, useTheme, useVenue, useHost, useStoryblokGlobalComponents } from '@odyssey/realtainment';
import { CURRENCY_CODE, prepareProducts } from '@/helpers';
import { ref, computed, watch, useContext } from '@nuxtjs/composition-api';
export default {
  name: 'EventGrid',
  components: {
    SEventGridBanners: () => import(/* webpackChunkName: "chunk-page-event" */ '@/components/Event/SEventGridBanners'),
    EventGridTitle: () => import(/* webpackChunkName: "chunk-page-event" */ '@/components/Event/EventGridTitle'),
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    ProductTile: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-product-tile" */ '@/components/UI/molecules/ProductTile'),
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton')
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    showNoEventsMessage: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    products: {
      type: [Array, Function],
      required: false,
      default: () => []
    },
    category: {
      type: Object,
      required: false,
      default: () => null
    },
    filters: {
      type: Object,
      required: false,
      default: () => null
    },
    currentFilters: {
      type: Object,
      required: false,
      default: () => null
    },
    defaultCategory: {
      type: String,
      required: false,
      default: ''
    },
    gtagItemListId: {
      type: String,
      required: false,
      default: ''
    },
    gtagItemListName: {
      type: String,
      required: false,
      default: ''
    },
    center: {
      type: Boolean,
      required: false,
      default: false
    },
    slotComponent: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const { prepareGTagItems } = useGTM();
    const { result: themes } = useTheme();
    const { result: venues } = useVenue();
    const { result: hosts } = useHost();
    const { globalComponents } = useStoryblokGlobalComponents();
    const context = useContext();
    const { i18n, $config, $gtm } = context;
    const promotions = ref([]);
    const computedProducts = computed(() => prepareProducts(props.products, context));

    const getBrand = $config.seo.defaultTitle;

    const banners = computed(() => {
      const type = computedProducts.value?.[0]?.typeId;
      const banners = globalComponents.value.eventBanners;

      return banners?.filter((banner) => banner.enabledFor.includes(type));
    });

    const trackEventView = (event, index) => {
      if (event) {
        $gtm.push(
          formatGtm('listing/EVENT_SELECT', {
            currency: CURRENCY_CODE(i18n),
            items: [prepareGTagItems(context, event, props.gtagItemListId, props.gtagItemListName, index)]
          })
        );
      }
    };

    const trackPromotionClick = ({ i, type }) => {
      $gtm.push(
        formatGtm('all/PROMOTION_SELECT', {
          promotion_id: type,
          creative_name: type,
          location_id: 'listing',
          index: i
        })
      );
    };

    // @todo: refactor promotion impression tracking
    const trackPromotionImpression = ({ i }) => {
      if (i === computedProducts.value?.length - 1 && promotions.value?.length > 0) {
        $gtm.push(
          formatGtm('all/PROMOTION_IMPRESSION', {
            items: promotions.value,
            location_id: 'listing',
            index: i
          })
        );
        promotions.value = [];
      }
    };

    const addPromotionImpression = ({ type, i }) => {
      promotions.value.push({
        promotion_id: type,
        creative_name: type,
        location_id: 'listing',
        index: i
      });
    };

    function trackEventImpressions(events) {
      $gtm.push(
        formatGtm('listing/EVENT_IMPRESSION', {
          currency: CURRENCY_CODE(i18n),
          location: 'listing',
          items: events.map((item, i) =>
            prepareGTagItems(context, item, item.categoryUuids?.[0]?.uuid, item.categorySlug, i)
          )
        })
      );
    }

    watch(
      computedProducts,
      (array) => {
        if (array.length) trackEventImpressions(array);
      },
      {
        immediate: true
      }
    );

    return {
      getBrand,
      computedProducts,
      trackPromotionImpression,
      addPromotionImpression,
      trackPromotionClick,
      trackEventView,
      banners,
      themes,
      hosts,
      venues
    };
  }
};
