//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toRefs, reactive, computed, useContext, ref } from '@nuxtjs/composition-api';
import useUiHelpers from '@/composables/useUiHelpers';
import difference from 'lodash/difference';
import {
  formatGtm,
  useProductFilters,
  FILTER_DATE,
  FILTER_SKILL,
  FILTER_HOST,
  FILTER_THEME,
  FILTER_THEME_CATEGORY,
  FILTER_CITY
} from '@odyssey/realtainment';

/*
 This filter panel component is responsible for:
 - A: rendering a basic panel with header, content, and footer
 - B: applying filters that have been selected / deselected in another component that is rendered as this component's content

A: Whenever the content is overflowing the maximum height of the content container, there should be some indication for it.
Currently, this is done using box-shadows on the header or footer element. For example, when the content is scrolled to the
top, but is heigher than the visible part of the content section, there will be a soft box shadow at the bottom to indicate
that there is more content the user can scroll to.

B: For desktop filters, the prop "filterTypes" will only contain a single filter type (FILTER_DATE, FILTER_SKILL, FILTER_HOST,
FILTER_THEME, FILTER_THEME_CATEGORY) as only one filter type should be filtered by each panel.
But on mobile, there is a unified filter panel for many filter types. By passing multiple filter types via the prop and
by putting multiple filters into the content section of this panel, one filter panel can be used to filter different things.

*/
export default {
  components: {
    CrossIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--cross.svg?inline'),
    OverflowContainer: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/OverflowContainer'),
    SButton: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton')
  },
  props: {
    filterTypes: {
      type: Array,
      required: true,
      validator: (prop) => {
        return prop.every((option) => {
          return [FILTER_DATE, FILTER_SKILL, FILTER_HOST, FILTER_THEME, FILTER_THEME_CATEGORY, FILTER_CITY].includes(
            option
          );
        });
      }
    },
    selectedCity: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { i18n, $gtm, localePath } = useContext();
    const { changeFilters: putFiltersIntoURL } = useUiHelpers();

    const {
      activeFilters,
      getURLFriendlyFilterKey,
      selectFilter: applyFilterValues,
      resetFilter
    } = useProductFilters();

    const slug = ref(null);

    const urlSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].selected;

        // we do not want to have duplicated city filters as the selected city is the route.path of the url
        if (filterValues.length && getURLFriendlyFilterKey(key) === 'city') {
          slug.value = filterValues[0];
        } else if (filterValues.length && getURLFriendlyFilterKey(key) !== 'city') {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const uiSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].values;

        // we do not want to have duplicated city filters as the selected city is the route.path of the url
        if (filterValues.length && getURLFriendlyFilterKey(key) === 'city') {
          slug.value = filterValues[0];
        } else if (filterValues.length && getURLFriendlyFilterKey(key) !== 'city') {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const canApplyFilters = computed(() => {
      const resultForEachFilterType = props.filterTypes.map((filterType) => {
        const urlFilterValues = urlSelectedFilters.value?.[getURLFriendlyFilterKey(filterType)] || [];
        const uiFilterValues = uiSelectedFilters.value?.[getURLFriendlyFilterKey(filterType)] || [];
        const diff = difference(urlFilterValues, uiFilterValues);
        const diff2 = difference(uiFilterValues, urlFilterValues);
        return !(diff.length === 0 && diff2.length === 0);
      });

      return resultForEachFilterType.some((r) => r === true) || props?.selectedCity?.length;
    });

    const applyFilters = () => {
      if (props.selectedCity) {
        putFiltersIntoURL(
          uiSelectedFilters.value,
          localePath({ name: 'variable-route', params: { slug: slug.value } })
        );
      } else {
        putFiltersIntoURL(uiSelectedFilters.value, localePath({ name: 'events-route' }));
      }

      props.filterTypes.forEach((filterType) => {
        const filterValues = uiSelectedFilters.value?.[getURLFriendlyFilterKey(filterType)];
        applyFilterValues(filterType, filterValues);
        $gtm.push(
          formatGtm('listing/FILTER_APPLY', {
            filter: filterType,
            selected: filterValues
          })
        );
      });

      emit('close');
    };

    const canResetFilters = computed(() => {
      const resultForEachFilterType = props.filterTypes.map((filterType) => {
        const urlFilterValues = urlSelectedFilters.value?.[getURLFriendlyFilterKey(filterType)] || [];
        return urlFilterValues.length > 0;
      });

      return resultForEachFilterType.some((r) => r === true) || props?.selectedCity?.length;
    });

    const resetFilters = () => {
      props.filterTypes.forEach((filterType) => {
        $gtm.push(
          formatGtm('listing/FILTER_RESET', {
            filterName: i18n.t('category.filters.filter.' + filterType)
          })
        );

        resetFilter(filterType);
      });
      putFiltersIntoURL(uiSelectedFilters.value, localePath({ name: 'themes-route' }));
      emit('reset-filter', null);
      emit('close');
    };

    const state = reactive({
      isOverflowingAtStart: false,
      isOverflowingAtEnd: false
    });

    const handleOverflowStartChange = (newValue) => {
      state.isOverflowingAtStart = newValue;
    };

    const handleOverflowEndChange = (newValue) => {
      state.isOverflowingAtEnd = newValue;
    };

    return {
      ...toRefs(state),
      handleOverflowStartChange,
      handleOverflowEndChange,
      canApplyFilters,
      applyFilters,
      canResetFilters,
      resetFilters,
      uiSelectedFilters,
      activeFilters
    };
  }
};
