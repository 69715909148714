//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { watch, computed, useContext, onMounted } from '@nuxtjs/composition-api';
import { onSSR } from '@odyssey/core';
import { useCache, CacheTagPrefix } from '@odyssey/realtainment-output-cache';
import useEventGrid from '@/composables/useEventGrid';
import useUiHelpers from '@/composables/useUiHelpers';
import { useProductFilters, formatGtm, useProduct } from '@odyssey/realtainment';
import { PRODUCT_EVENT_TYPES, PRODUCT_ONLINE_EVENT_TYPES } from '@/helpers';
export default {
  components: {
    SButton: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner')
  },
  setup() {
    const { params, query, route, $gtm, $config } = useContext();
    const { getFacetsFromURL } = useUiHelpers();
    const {
      fetchEventDataClient,
      fetchEventData,
      currentPage,
      setCurrentPage,
      setLoading,
      canLoadMoreOnClick: checkCanLoadMoreProducts,
      loading: isLoadingProducts,
      events,
      reset: resetEventGrid
    } = useEventGrid();
    const { products, search: searchProducts } = useProduct();
    const { getURLFilters, activeFilters, resetFilter } = useProductFilters();
    const isKidsEventPage = computed(() => route.value.name.includes('themes-route-kids'));

    const isMotivPage = computed(() => route.value?.path === '/motiv/');

    const computedFilters = computed(() => {
      const filters = {
        themeCategoriesUuids: $config.theme.kids_category
      };
      return isKidsEventPage.value ? filters : getURLFilters(params.value.slug, getFacetsFromURL());
    });

    const computedProducts = computed(() => {
      if (route.value.name.includes('themes-route')) {
        const themeUuids = events.value.map((o) => o.eventThemeUuid);
        const filteredEvents = events.value.filter(
          ({ eventThemeUuid }, index) => !themeUuids.includes(eventThemeUuid, index + 1)
        );

        return filteredEvents;
      }

      return events.value || [];
    });

    onMounted(async () => {
      await fetchEventDataClient(products);
    });

    const fetchProducts = async () => {
      await setLoading(true);
      await searchProducts({
        page: currentPage.value,
        limit: route.value.name.includes('themes-route') ? 30 : 18,
        typeIds: isMotivPage.value ? [PRODUCT_EVENT_TYPES] : [PRODUCT_EVENT_TYPES, PRODUCT_ONLINE_EVENT_TYPES],
        areSalesStopped: false,
        ...computedFilters.value
      });
      await fetchEventData(products);
    };

    const loadMoreProducts = async () => {
      await setLoading(true);
      $gtm.push(
        formatGtm('listing/EVENT_SHOW_MORE', {
          location: route.value.meta.pageType
        })
      );
    };

    const queryFilters = computed(() => {
      return { query: { ...query.value, page: currentPage.value + 1 } };
    });

    onSSR(async () => {
      const { addTags } = useCache();
      // @todo handle error when category is not found
      // if (!category.value) root.context.$nuxt.error({ statusCode: 404 });
      resetEventGrid();

      if (query.value?.page) {
        setCurrentPage(parseInt(query.value.page));
      }

      await fetchProducts();
      addTags([{ prefix: CacheTagPrefix.Category, value: 'category' }]);
    });

    watch(params, async (oldParams, newParams) => {
      if (oldParams.slug !== newParams.slug) {
        Object.keys(activeFilters.value).map((key) => resetFilter(key));
      }
    });

    watch(query, async (newVal, oldVal) => {
      if (newVal?.page && newVal.page !== oldVal?.page) {
        setCurrentPage(parseInt(query.value.page));
      } else {
        resetEventGrid();
      }
      await fetchProducts();
      await fetchEventDataClient(products);
    });

    return {
      computedProducts,
      isLoadingProducts,
      checkCanLoadMoreProducts,
      currentPage,
      setLoading,
      loadMoreProducts,
      queryFilters
    };
  }
};
