//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useProductFilters, FILTER_CITY } from '@odyssey/realtainment';
export default {
  name: 'CitySelectorFilter',
  components: {
    CitySelector: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/selects/CitySelector')
  },
  props: {
    cities: {
      type: Array,
      default: () => []
    },
    city: {
      type: Object,
      default: () => null
    }
  },
  setup(_, { emit }) {
    const { updateFilter, resetFilter } = useProductFilters();
    function onUpdateFilter(val) {
      emit('updated-filter', val);
      resetFilter(FILTER_CITY);
      updateFilter(FILTER_CITY, val);
    }

    return {
      onUpdateFilter
    };
  }
};
