//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { FILTER_DATE, useProductFilters } from '@odyssey/realtainment';

export default {
  components: {
    MultiDatePicker: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/MultiDatePicker')
  },
  setup() {
    const { filters, updateFilter: uiToggleFilter, getURLFriendlyFilterKey, activeFilters } = useProductFilters();

    const filterKey = FILTER_DATE;
    const hostFilter = filters.value[filterKey];

    const uiSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].values;
        if (filterValues.length) {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const filterOptions = computed(() => {
      return hostFilter?.options || [];
    });

    const selectedFilterOptions = computed(() => {
      return (uiSelectedFilters.value[getURLFriendlyFilterKey(filterKey)] || []).map((id) => ({
        id
      }));
    });

    const handleFilterOptionSelectionChanged = (option) => {
      uiToggleFilter(filterKey, option.id);
    };

    return {
      filterOptions,
      selectedFilterOptions,
      handleFilterOptionSelectionChanged
    };
  }
};
