var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-listing-filters",attrs:{"data-testid":"productListingFilters"}},[_c('div',{staticClass:"product-listing-filters__buttons"},[(!_vm.isProductPage)?_c('button',{staticClass:"product-listing-filters__filter-button visible city",on:{"click":function () { return _vm.toggleCitySelectionModal(); }}},[_c('span',[_vm._v(_vm._s(_vm.selectedCity && _vm.category && _vm.category.name ? _vm.category.name : _vm.$t('common.city')))]),_vm._v(" "),_c('CaretDownIcon')],1):_vm._e(),_vm._v(" "),_vm._l((_vm.dropdownFilters),function(filterGroup,key){return (!_vm.isProductPage)?_c('Popper',{key:key,staticClass:"product-listing-filters__filter-popper",attrs:{"placement":"bottom-start","offsetSkid":"-12","offsetDistance":"0"},on:{"open":function () { return _vm.toggleFilterPanel('open', _vm.getFilterName(filterGroup.name)); },"close":function () { return _vm.toggleFilterPanel('close', _vm.getFilterName(filterGroup.name)); }},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var close = ref.close;
return [_c('ProductListingFilterPanel',{attrs:{"filterTypes":[key],"selectedCity":_vm.selectedCity},on:{"close":close,"reset-filter":function () { return _vm.updateCitySelected(null); }}},[(key === _vm.FILTER_CITY)?[_c('ProductListingCitySelectFilter',{on:{"updated-filter":_vm.updateCitySelected}})]:_vm._e(),_vm._v(" "),(key === _vm.FILTER_THEME_CATEGORY)?[_c('ProductListingThemeCategoryFilter')]:_vm._e(),_vm._v(" "),(key === _vm.FILTER_DATE)?[_c('ProductListingDateFilter')]:_vm._e(),_vm._v(" "),(key === _vm.FILTER_SKILL)?[_c('ProductListingSkillLevelFilter')]:_vm._e(),_vm._v(" "),(key === _vm.FILTER_HOST)?[_c('ProductListingHostFilter')]:_vm._e()],2)]}}],null,true)},[_c('button',{class:{
          'product-listing-filters__filter-button': true,
          'visible-on-mobile': key === _vm.FILTER_THEME_CATEGORY,
          active: _vm.hasSomeFilterValuesSelectedForType(key)
        }},[_vm._v("\n        "+_vm._s(_vm.selectedCity && _vm.category && _vm.category.name
            ? _vm.getFilterName(filterGroup.name, _vm.category.name)
            : _vm.getFilterName(filterGroup.name))+"\n        "),_c('CaretDownIcon')],1)]):_vm._e()}),_vm._v(" "),_c('button',{staticClass:"product-listing-filters__mobile-filter-button",on:{"click":_vm.openMobileFilterModal}},[_vm._v("\n      "+_vm._s(_vm.$t('category.filters.label'))+"\n    ")])],2),_vm._v(" "),(_vm.shouldShowCitySelectionModal && !_vm.isProductPage)?_c('CityAndDateSelect',{attrs:{"isVisible":_vm.shouldShowCitySelectionModal,"startAt":0},on:{"close":_vm.toggleCitySelectionModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }