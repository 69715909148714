//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { FILTER_SKILL, useProductFilters } from '@odyssey/realtainment';

export default {
  components: {
    ProductListingFilterList: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingFilterList'
      )
  },
  setup () {
    const { filters, updateFilter: uiToggleFilter, getURLFriendlyFilterKey, activeFilters } = useProductFilters();

    const filterKey = FILTER_SKILL;
    const skillLevelFilter = filters.value[filterKey];

    const uiSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].values;
        if (filterValues.length) {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const filterOptions = computed(() => {
      const options = skillLevelFilter?.options || [];

      return [
        {
          id: -1,
          count: options.reduce((sum, option) => sum + option.count, 0),
          isSelected: !uiSelectedFilters.value?.[getURLFriendlyFilterKey(filterKey)]?.length,
          label: 'Alle Schwierigkeitsgrade'
        },
        ...options
          .map((option) => {
            return {
              id: option.id,
              count: option.count,
              isSelected: uiSelectedFilters.value?.[getURLFriendlyFilterKey(filterKey)]?.includes(option.id) || false,
              label: option.name
            };
          })
          .sort((a, b) => b.count - a.count)
      ];
    });

    const handleFilterOptionSelectionChanged = (option) => {
      if (option.id === -1) {
        filterOptions.value.forEach((filterOption) => {
          if (filterOption.id === option.id) {
            // skip the "all" option
            return;
          }

          if (filterOption.isSelected) {
            uiToggleFilter(filterKey, filterOption.id);
          }
        });

        return;
      }

      uiToggleFilter(filterKey, option.id);
    };

    return {
      filterOptions,
      handleFilterOptionSelectionChanged
    };
  }
};
