//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';
import EventGrid from '@/components/Event/SEventGrid';
import STabbedEventGridItem from './STabbedEventGridItem';
export default {
  inheritAttrs: false,
  props: {
    gridKey: {
      type: String,
      required: true,
      default: 'storyblok-grid'
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    showSoldOutEvents: {
      type: Boolean,
      required: false,
      default: false
    },
    showSalesStoppedEvents: {
      type: Boolean,
      required: false,
      default: false
    },
    showCitySelect: {
      type: Boolean,
      required: false,
      default: false
    },
    hideLoadMoreButton: {
      type: Boolean,
      required: false,
      default: false
    },
    removeDuplicateThemes: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String,
      required: false,
      default: 'grid'
    },
    limit: {
      type: Number | Object,
      required: true
    },
    gridFilters: {
      type: Array | Object,
      required: false,
      default: () => []
    }
  },
  components: {
    EventGrid,
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    STabbedEventGridItem
  },
  setup(props) {
    const {
      app: { $screen },
      $ua
    } = useContext();
    const categorySlug = computed(() => props.gridFilters?.taxonList?.[0]?.value || null);

    const limitResults = computed(() => {
      if (typeof props.limit === 'object' && props.limit !== null) {
        const limit = {
          ...props.limit,
          xl: props.limit.lg
        };

        let breakpoint = $screen.breakpoint;
        if (process.server) {
          switch ($ua.deviceType()) {
            case 'pc':
              breakpoint = 'lg';
              break;
            case 'tablet':
              breakpoint = 'md';
              break;
            case 'mobile':
              breakpoint = 'sm';
              break;
          }
        }

        return limit[breakpoint];
      }
      return props.limit;
    });

    return {
      categorySlug,
      limitResults
    };
  }
};
