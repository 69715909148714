//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed } from '@nuxtjs/composition-api';
import { uniqueId } from 'lodash';

export default {
  components: {
    CheckboxCheckedIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--checkbox-checked.svg?inline'),
    CheckboxUncheckedIcon: () =>
      import(
        /* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--checkbox-unchecked.svg?inline'
      ),
    RadioCheckedIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--radio-checked.svg?inline'),
    RadioUncheckedIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--radio-unchecked.svg?inline'),
    MagnifyingGlassIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--magnifying-glass.svg?inline')
  },
  props: {
    type: {
      type: String,
      validator: (val) => ['checkbox', 'radio'].includes(val),
      default: 'checkbox'
    },
    options: {
      type: Array,
      required: true,
      validator: (prop) => {
        return prop.every(
          (option) =>
            typeof option === 'object' &&
            // https://eslint.org/docs/latest/rules/no-prototype-builtins
            Object.prototype.hasOwnProperty.call(option, 'label') &&
            Object.prototype.hasOwnProperty.call(option, 'isSelected')
        );
      }
    },
    shouldShowSearch: {
      type: Boolean,
      default: false
    },
    searchInputPlaceholder: {
      type: String,
      default: 'Suchen'
    }
  },
  model: {
    prop: 'options',
    event: 'option:change'
  },
  setup(props) {
    const uniqueInputId = ref('product-listing-filter-list_' + uniqueId());
    const searchInputText = ref(null);

    const filteredOptions = computed(() => {
      return !searchInputText.value
        ? props.options
        : props.options.filter((option) =>
            String(option.label).toLowerCase().includes(searchInputText.value.toLowerCase())
          );
    });

    return {
      uniqueInputId,
      searchInputText,
      filteredOptions
    };
  }
};
