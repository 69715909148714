//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted, reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import {
  FILTER_THEME,
  FILTER_THEME_CATEGORY,
  FILTER_SKILL,
  FILTER_HOST,
  FILTER_DATE,
  FILTER_CITY,
  useProductFilters,
  useCategory,
  useProduct,
  formatGtm
} from '@odyssey/realtainment';
import { useGlobalUI, GlobalUIElements } from '@/composables/useGlobalUI';
import { prepareProduct } from '@/helpers';
import { pickBy } from 'lodash';
export default {
  components: {
    Popper: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Popper'),
    ProductListingThemeCategoryFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingThemeCategoryFilter'
      ),
    ProductListingFilterPanel: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingFilterPanel'
      ),
    CaretDownIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--caret-down.svg?inline'),
    ProductListingDateFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingDateFilter'
      ),
    ProductListingSkillLevelFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingSkillLevelFilter'
      ),
    ProductListingHostFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingHostFilter'
      ),
    ProductListingCitySelectFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingCitySelectorFilter'
      ),
    CityAndDateSelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/selects/CityAndDateSelect'),
    CitySelector: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/selects/CitySelector'),
    // SCitySelect: () =>
    //   import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/selects/SCitySelect'),
    CategorySelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/selects/CategorySelect')
  },
  setup({ root }) {
    const context = useContext();
    const { route, params, $gtm } = context;
    const { setGlobalUI } = useGlobalUI();

    const { products } = useProduct();
    const { search, filters, getFilterName, activeFilters, getURLFriendlyFilterKey } = useProductFilters();

    const { categories } = useCategory();
    const state = reactive({
      shouldShowCitySelectionModal: false,
      value: {},
      selectedCity: null
    });
    const isCitySelectVisible = computed(() => root?.$screen?.width <= 1024);

    const openMobileFilterModal = () => {
      setGlobalUI(GlobalUIElements.FiltersModal, true);
    };

    const product = computed(() =>
      prepareProduct(products.value?.products?.find((p) => p.slug === params.value.slug) || null, context)
    );

    const category = computed(() =>
      categories.value?.find(
        (c) => params.value?.slug && String(params.value?.slug).length > 0 && c?.slug === params.value?.slug
      )
    );

    const isProductPage = computed(
      () => route.value.name.includes('product-event') || route.value.name.includes('theme__')
    );

    const urlSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].selected;
        if (filterValues.length) {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const hasSomeFilterValuesSelectedForType = (filterType) => {
      return urlSelectedFilters.value[getURLFriendlyFilterKey(filterType)]?.length > 0;
    };

    const dropdownFilters = computed(() => {
      const value = filters.value;

      const result = pickBy(value, (filterValue, filterKey) => {
        switch (filterKey) {
          case FILTER_THEME:
            return false;
        }

        return true;
      });

      return result;
    });

    onMounted(async () => {
      let taxon = null;

      if (!route.value.name.includes('theme__')) {
        route.value.params?.slug ? route.value.params.slug?.replace('-', '_') : null;
      }
      if (route.value.name.includes('product-event')) {
        taxon = product?.value?.categorySlug ?? null;
      }

      await search({
        taxon
      });
    });

    const dropdownFilterTypes = computed(() => {
      return Object.keys(dropdownFilters.value);
    });

    function toggleCitySelectionModal() {
      state.shouldShowCitySelectionModal = !state.shouldShowCitySelectionModal;
      if (state.shouldShowCitySelectionModal) {
        $gtm.push(
          formatGtm('listing/CITY_PANEL_OPEN', {
            location: route.value.meta.pageType
          })
        );
      }
    }

    function toggleFilterPanel(state, filter) {
      if (state === 'open') {
        $gtm.push(
          formatGtm('listing/FILTER_OPEN', {
            filter
          })
        );
      }
    }

    const updateCitySelected = (data) => {
      state.selectedCity = data;
    };

    return {
      ...toRefs(state),
      isProductPage,
      category,
      getFilterName,
      dropdownFilters,
      dropdownFilterTypes,
      FILTER_SKILL,
      FILTER_HOST,
      FILTER_DATE,
      FILTER_CITY,
      FILTER_THEME_CATEGORY,
      openMobileFilterModal,
      hasSomeFilterValuesSelectedForType,
      toggleCitySelectionModal,
      toggleFilterPanel,
      updateCitySelected,
      isCitySelectVisible
    };
  }
};
