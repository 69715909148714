//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed, onMounted, useContext } from '@nuxtjs/composition-api';
import { onSSR } from '@odyssey/core';
import { useProductFilters, formatGtm, useCategory, useProduct } from '@odyssey/realtainment';
import useEventGrid from '@/composables/useEventGrid';
import useUiHelpers from '@/composables/useUiHelpers';

export default {
  components: {
    SEventGrid: () => import(/* webpackChunkName: "chunk-page-product" */ '@/components/Event/SEventGrid'),
    EventGridTitle: () => import(/* webpackChunkName: "chunk-page-product" */ '@/components/Event/EventGridTitle'),
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    Dropdown: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Dropdown'),
    SCarousel: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ '@/components/UI/molecules/carousels/SCarousel')
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: String(Math.random())
    },
    categorySlug: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String,
      required: false,
      default: 'grid'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showSoldOutEvents: {
      type: Boolean,
      default: false
    },
    showSalesStoppedEvents: {
      type: Boolean,
      default: false
    },
    showCitySelect: {
      type: Boolean,
      required: false,
      default: false
    },
    hideLoadMoreButton: {
      type: Boolean,
      required: false,
      default: false
    },
    removeDuplicateThemes: {
      type: Boolean,
      required: false,
      default: false
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({})
    },
    limit: {
      type: Number,
      default: 18
    }
  },
  setup(props) {
    const th = useUiHelpers();
    const { $gtm, route, $config } = useContext();
    const { categories } = useCategory();
    const { products, search: searchProducts } = useProduct(`GridItemProducts--${props.id}`);
    const { products: allProducts, search: searchAllProducts } = useProduct(`GridItemProductsUnpaged--${props.id}`);
    const {
      fetchEventData,
      currentPage,
      setCurrentPage,
      setLoading,
      canLoadMoreOnClick,
      loading,
      events,
      reset: resetEventGrid
    } = useEventGrid(`GridItemEvents--${props.id}`);
    const { getURLFilters } = useProductFilters();

    const getBrand = $config.seo.defaultTitle;
    const selectedCategory = ref(null);
    const category = computed(() => categories.value.find((c) => c.slug === props.categorySlug));
    const computedFilters = computed(() => {
      const filters = {
        isSoldOut: props.showSoldOutEvents === true ? null : false,
        areSalesStopped: props.showSalesStoppedEvents === true ? null : false
      };

      if (Array.isArray(props.filters?.filters) && props.filters?.filters?.length > 0) {
        props.filters?.filters?.forEach((filter) => {
          // backwards compatibility for old filters
          if (filter.name === 'category_ids') {
            filter = {
              name: 'taxon',
              value: props.categorySlug?.replace('-', '_')
            };
          }
          filters[filter.name] = filter.value;
        });
      } else if (typeof props.filters?.multiFilters === 'object') {
        Object.keys(props.filters.multiFilters).forEach((key) => {
          if (Array.isArray(props.filters.multiFilters[key]) && props.filters.multiFilters[key].length > 0) {
            filters[key] = props.filters.multiFilters[key].map((filter) => filter.value);
          }
        });
      }

      if (selectedCategory.value !== null && (filters.taxonList?.length === 0 || filters.taxonList === undefined)) {
        return {
          ...filters,
          taxon: selectedCategory?.value?.replace('-', '_')
        };
      }

      if (props.categorySlug?.length > 0) {
        return {
          ...filters,
          taxon: props.categorySlug?.replace('-', '_')
        };
      }

      return filters;
    });
    const computedCategories = computed(() => {
      if (props.showCitySelect) {
        const allProductsCategories = allProducts.value?.products?.map((p) => p.categorySlug) || [];
        return categories.value.filter(
          (category) => category.includeInMenu && allProductsCategories.includes(category.slug)
        );
      }
      return categories.value.filter((category) => category.includeInMenu);
    });
    const computedEvents = computed(() => {
      if (props.removeDuplicateThemes) {
        const themeUuids = events.value.map((o) => o.eventThemeUuid);
        return events.value.filter(({ eventThemeUuid }, index) => !themeUuids.includes(eventThemeUuid, index + 1));
      }
      return events.value || [];
    });

    async function fetchProducts() {
      await setLoading(true);
      await searchProducts({
        page: currentPage.value,
        limit: props.limit,
        // ...getURLFilters(props.categorySlug, th.getFacetsFromURL()),
        ...computedFilters.value
      });
      await fetchEventData(products);

      if (props.showCitySelect && currentPage.value === 1 && selectedCategory.value === null) {
        await searchAllProducts({
          ...getURLFilters(props.categorySlug, th.getFacetsFromURL()),
          ...computedFilters.value
        });
      }
    }

    async function loadMoreSectionProducts() {
      setCurrentPage(currentPage.value + 1);

      await fetchProducts();
      $gtm.push(
        formatGtm('listing/EVENT_SHOW_MORE', {
          location: route.value.meta.pageType
        })
      );
    }

    async function onCitySelected(city) {
      resetEventGrid();
      selectedCategory.value = city.slug;
      await fetchProducts();
    }

    onSSR(async () => {
      await fetchProducts();
    });

    onMounted(async () => {
      if (events.value.length === 0) await fetchProducts();
    });

    return {
      getBrand,
      currentPage,
      category,
      computedCategories,
      loading,
      loadMoreSectionProducts,
      canLoadMoreOnClick,
      resetEventGrid,
      onCitySelected,
      selectedCategory,
      computedFilters,
      computedEvents
    };
  }
};
