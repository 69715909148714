//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { FILTER_THEME_CATEGORY, useProductFilters } from '@odyssey/realtainment';

export default {
  components: {
    ProductListingFilterList: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingFilterList'
      )
  },
  props: {},
  setup() {
    const { filters, updateFilter: uiToggleFilter, getURLFriendlyFilterKey, activeFilters } = useProductFilters();

    const filterKey = FILTER_THEME_CATEGORY;
    const themeCategoryFilter = filters.value[filterKey];

    const uiSelectedFilters = computed(() => {
      return Object.keys(activeFilters.value).reduce((acc, key) => {
        const filterValues = activeFilters.value[key].values;
        if (filterValues.length) {
          acc[getURLFriendlyFilterKey(key)] = filterValues;
        }
        return acc;
      }, {});
    });

    const filterOptions = computed(() => {
      const options = themeCategoryFilter?.options || [];

      return options
        .map((option) => {
          return {
            id: option.id,
            count: option.count,
            isSelected: uiSelectedFilters.value?.[getURLFriendlyFilterKey(filterKey)]?.includes(option.id) || false,
            label: option.name || ''
          };
        })
        .sort((a, b) => b.count - a.count);
    });

    const handleFilterOptionSelectionChanged = (option) => {
      uiToggleFilter(filterKey, option.id);
    };

    return {
      filterOptions,
      handleFilterOptionSelectionChanged
    };
  }
};
