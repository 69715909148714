import { sharedRef } from '@odyssey/core';
import { computed } from '@nuxtjs/composition-api';
import uniq from 'lodash/uniq';
import { useEvent } from '@odyssey/realtainment';
import { isBottomVisible } from '@/helpers/infiniteScroll';
import Vue from 'vue';

const useEventGrid = (id: string) => {
  const { searchData } = useEvent();

  const currentPage = sharedRef(1, `useEventGrid--currentPage--${id}`);
  const lastPage = sharedRef(1, `useEventGrid--lastPage--${id}`);
  const loading = sharedRef(false, `useEventGrid--loading--${id}`);
  const events = sharedRef([], `useEventGrid--data--${id}`);

  async function setLoading(isLoading: boolean): Promise<void> {
    await Vue.nextTick();
    loading.value = isLoading;
  }

  function setCurrentPage(page: number) {
    currentPage.value = page;
  }

  async function fetchEventDataClient(productResponse: any): Promise<void> {
    const products = productResponse.value?.products || [];
    const filter_host = uniq(products.map((p) => p.hostUuid));
    const filter_venue = uniq(products.map((p) => p.venueUuid));

    await searchData({
      filter_host,
      filter_venue
    });
  }

  async function fetchEventData(productResponse: any): Promise<void> {
    const products = productResponse.value?.products || [];
    lastPage.value = productResponse.value?.pagination?.lastPage || 1;

    function filterProducts(products) {
      return products.filter((p) => p.visible);
    }

    const filter_event = uniq(products.map((p) => p.externalIdentifier));
    const filter_theme = uniq(products.map((p) => p.eventThemeUuid));
    const filter_theme_category = uniq(products.map((p) => p?.themeCategoryUuid)?.flat());

    await searchData({
      filter_event,
      filter_theme,
      filter_theme_category
    });

    if (currentPage.value > 1) {
      const fetchedProducts = filterProducts(products);
      events.value = [...events.value, ...fetchedProducts];
      await setLoading(false);
      return;
    }
    events.value = filterProducts(products);
    await setLoading(false);
  }

  function canLoadMoreOnScroll(): boolean {
    return isBottomVisible() && !loading.value && currentPage.value < lastPage.value;
  }

  function canLoadMoreOnClick(): boolean {
    return !loading.value && currentPage.value < lastPage.value;
  }

  function reset(): void {
    events.value = [];
    currentPage.value = 1;
    lastPage.value = 1;
  }

  return {
    setLoading,
    setCurrentPage,
    fetchEventDataClient,
    fetchEventData,
    canLoadMoreOnClick,
    canLoadMoreOnScroll,
    reset,
    loading,
    currentPage,
    events: computed(() => events.value)
  };
};

export default useEventGrid;
