//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted, reactive, toRefs, useContext } from '@nuxtjs/composition-api';
import {
  FILTER_THEME,
  FILTER_THEME_CATEGORY,
  FILTER_SKILL,
  FILTER_HOST,
  FILTER_DATE,
  FILTER_CITY,
  useProductFilters,
  useProduct,
  useCategory
} from '@odyssey/realtainment';
import { useGlobalUI, GlobalUIElements } from '@/composables/useGlobalUI';
import { prepareProduct } from '@/helpers';
import { pickBy } from 'lodash';
export default {
  components: {
    ProductListingThemeCategoryFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingThemeCategoryFilter'
      ),
    ProductListingFilterPanel: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingFilterPanel'
      ),
    ProductListingDateFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingDateFilter'
      ),
    ProductListingSkillLevelFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingSkillLevelFilter'
      ),
    ProductListingHostFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingHostFilter'
      ),
    ProductListingCitySelectFilter: () =>
      import(
        /* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/Filters/ProductListingCitySelectorFilter'
      ),
    CityAndDateSelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/selects/CityAndDateSelect'),
    Modal: () => import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/modals/Modal'),
    Accordion: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Accordion')
  },
  setup({ root }) {
    const context = useContext();
    const { route, params } = context;
    const { categories } = useCategory();
    const { isGlobalUIActive, setGlobalUI } = useGlobalUI();

    const { products } = useProduct();
    const { search, filters, getFilterName, activeFilters } = useProductFilters();

    const state = reactive({
      shouldShowMobileFilterModal: false,
      shouldShowCitySelectionModal: false,
      value: {},
      selectedCity: null
    });
    const isCitySelectVisible = computed(() => root?.$screen?.width <= 1024);

    const closeMobileFilterModal = () => {
      state.shouldShowMobileFilterModal = false;
      setGlobalUI(GlobalUIElements.FiltersModal, false);
    };

    const product = computed(() =>
      prepareProduct(products.value?.products?.find((p) => p.slug === params.value.slug) || null, context)
    );

    const selectedCity = computed(() => {
      if (activeFilters.value?.[FILTER_CITY]?.values?.length > 0) {
        const cat = categories.value.find((c) => c.slug === activeFilters.value?.[FILTER_CITY]?.values?.[0]);
        if (cat) return cat.name;
      }
      return null;
    });

    const dropdownFilters = computed(() => {
      const value = filters.value;

      const result = pickBy(value, (filterValue, filterKey) => {
        switch (filterKey) {
          case FILTER_THEME:
            return false;
        }

        return true;
      });

      return result;
    });

    onMounted(async () => {
      let taxon = route.value.params?.slug ? route.value.params.slug?.replace('-', '_') : null;
      if (route.value.name.includes('product-event')) {
        taxon = product.value?.categorySlug ?? null;
      }

      await search({
        taxon
      });
    });

    const dropdownFilterTypes = computed(() => {
      return Object.keys(dropdownFilters.value);
    });

    function onCloseMobileFilterModal() {
      setGlobalUI(GlobalUIElements.FiltersModal, false);
    }

    return {
      ...toRefs(state),
      selectedCity,
      isGlobalUIFiltersActive: isGlobalUIActive(GlobalUIElements.FiltersModal),
      getFilterName,
      dropdownFilters,
      dropdownFilterTypes,
      FILTER_SKILL,
      FILTER_HOST,
      FILTER_DATE,
      FILTER_CITY,
      FILTER_THEME_CATEGORY,
      onCloseMobileFilterModal,
      closeMobileFilterModal,
      isCitySelectVisible
    };
  }
};
